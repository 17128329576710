import type { AnimalClinicalObservation } from './animal-clinical-observation.interface';
import type { AnimalComment } from './animal-comment.interface';
import type { AnimalDiagnosticObservation } from './animal-diagnostic-observation.interface';
import type { AnimalHealthRecord } from './animal-health-record.interface';
import type { AnimalShipment } from './animal-shipment.interface';
import type { Birth } from './birth.interface';
import type { EventType } from './event.interface';
import type { Genotype } from './genotype.interface';
import type { Material } from './material.interface';
import type { MatingPlugDate } from './mating-plug-date.interface';
import type { Order } from './order.interface';
import type { TaxonCharacteristicInstance } from './taxon-characteristic-instance.interface';
import type { TreatmentPlan } from './treatment-plan.interface';
import type { cv_AnimalClassification } from './cv-animal-classification.interface';
import type { cv_AnimalMatingStatus } from './cv-animal-mating-status.interface';
import type { cv_AnimalStatus } from './cv-animal-status.interface';
import type { cv_AnimalUse } from './cv-animal-use.interface';
import type { cv_BodyConditionScore } from './cv-body-condition-score.interface';
import type { cv_BreedingStatus } from './cv-breeding-status.interface';
import type { cv_Diet } from './cv-diet.interface';
import type { cv_ExitReason } from './cv-exit-reason.interface';
import type { cv_Generation } from './cv-generation.interface';
import type { cv_IACUCProtocol } from './cv-iacuc-protocol.interface';
import type { cv_PhysicalMarkerType } from './cv-physical-marker-type.interface';
import type { cv_Sex } from './cv-sex.interface';
import type { Permit } from './permit.interface';
import type { SeverityScore } from './severity-score.interface';

export interface Animal {
    AlternatePhysicalID: string | null;
    AnimalClinicalObservation: AnimalClinicalObservation[];
    AnimalComment: AnimalComment[];
    AnimalCommentsSortable: string | null;
    AnimalDiagnosticObservation: AnimalDiagnosticObservation[];
    AnimalHealthRecord: AnimalHealthRecord | null;
    AnimalName: string;
    AnimalNameSortable: string;
    AnimalShipment: AnimalShipment | null;
    Birth: Birth | null;
    C_AnimalClassification_key: number | null;
    C_AnimalMatingStatus_key: number | null;
    C_AnimalShipment_key: number | null;
    C_AnimalStatus_key: number | null;
    C_AnimalUse_key: number | null;
    C_Birth_key: number | null;
    C_BodyConditionScore_key: number | null;
    C_BreedingStatus_key: number | null;
    C_Diet_key: number | null;
    C_ExitReason_key: number | null;
    C_Generation_key: number | null;
    C_IACUCProtocol_key: number | null;
    C_Material_key: number;
    C_Order_key: number | null;
    C_Permit_key: number | null;
    C_PhysicalMarkerType_key: number | null;
    C_Sex_key: number | null;
    C_Workgroup_key: number;
    CITESNumber: number;
    CohortsSortable: string | null;
    Comments: string | null;
    CreatedBy: string;
    DateBorn: Date | null;
    DateCreated: Date | null;
    DateExit: Date | null;
    DateModified: Date | null;
    DateOnPermit : Date | null;
    DateOrigin: Date | null;
    DateReceived: Date | null;
    Event: EventType[];
    ExitReasonDetails: string | null;
    Genotype: Genotype[];
    GenotypesSortable: string | null;
    HeldFor: string;
    JobIDsSortable: string;
    Material: Material;
    MaterialPoolsSortable: string | null;
    MatingPlugDate: MatingPlugDate[];
    ModifiedBy: string;
    Order: Order | null;
    OrderID: string | null;
    Owner: string;
    Permit: Permit | null;
    PreparedSortable: string | null;
    PhysicalMarker: string | null;
    SeverityDatesRecordedSortable: string | null;
    SeverityExternalCommentsSortable: string | null;
    SeverityInternalCommentsSortable: string | null;
    SeverityScore: SeverityScore[];
    SeverityScoresSortable: string | null;
    ShipmentID: string | null;
    TaxonCharacteristicInstance: TaxonCharacteristicInstance[];
    TreatmentPlan: TreatmentPlan[];
    VendorID: string | null;
    Version: number;
    cv_AnimalClassification: cv_AnimalClassification | null;
    cv_AnimalMatingStatus: cv_AnimalMatingStatus | null;
    cv_AnimalStatus: cv_AnimalStatus | null;
    cv_AnimalUse: cv_AnimalUse | null;
    cv_BodyConditionScore: cv_BodyConditionScore | null;
    cv_BreedingStatus: cv_BreedingStatus | null;
    cv_Diet: cv_Diet | null;
    cv_ExitReason: cv_ExitReason | null;
    cv_Generation: cv_Generation | null;
    cv_IACUCProtocol: cv_IACUCProtocol | null;
    cv_PhysicalMarkerType: cv_PhysicalMarkerType | null;
    cv_Sex: cv_Sex | null;
}
