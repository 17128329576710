import type { AnimalPlaceholder } from './animal-placeholder.interface';
import type { Institution } from './institution.interface';
import type { JobCharacteristicInstance } from './job-characteristic-instance.interface';
import type { JobCohort } from './job-cohort.interface';
import type { JobGroup } from './job-group.interface';
import type { JobInstitution } from './job-institution.interface';
import type { JobLine } from './job-line.interface';
import type { JobLocation } from './job-location.interface';
import type { JobMaterial } from './job-material.interface';
import type { JobMaterialPool } from './job-material-pool.interface';
import type { JobOrder } from './job-order.interface';
import type { JobStandardPhrase } from './job-standard-phrase.interface';
import type { JobTestArticle } from './job-test-article.interface';
import type { JobWorkflowTask } from './job-workflow-task.interface';
import type { Line } from './line.interface';
import type { MessageMap } from './message-map.interface';
import type { Note } from './note.interface';
import type { Order } from './order.interface';
import type { Placeholder } from './placeholder.interface';
import type { Site } from './site.interface';
import type { StoredFileMap } from './stored-file-map.interface';
import type { Study } from './study.interface';
import type { TaskJob } from './task-job.interface';
import type { cv_Compliance } from './cv-compliance.interface';
import type { cv_IACUCProtocol } from './cv-iacuc-protocol.interface';
import type { cv_JobReport } from './cv-job-report.interface';
import type { cv_JobStatus } from './cv-job-status.interface';
import type { cv_JobSubtype } from './cv-job-subtype.interface';
import type { cv_JobType } from './cv-job-type.interface';
import { JobReportReference } from './job-report-reference.interface';
import { JobVariablePhrase } from './job-variable-phrase.interface';
import { Permit } from './permit.interface';

export interface Job {
    AccountNumber: string;
    AnimalPlaceholder: AnimalPlaceholder[];
    C_Compliance_key: number | null;
    C_IACUCProtocol_key: number | null;
    C_Institution_key: number | null;
    C_JobReport_key: number | null;
    C_JobStatus_key: number;
    C_JobSubtype_key: number | null;
    C_JobType_key: number;
    C_Job_key: number;
    C_Line_key: number | null;
    C_ServiceOrder_key: number | null;
    C_Severity_key: number | null;
    C_Site_key: number | null;
    C_Study_key: number | null;
    C_Workgroup_key: number;
    ClientManager: string;
    Compliance: cv_Compliance;
    Cost: number | null;
    Counter: number | null;
    CreatedBy: string;
    CurrentLocationPath: string;
    DateCreated: Date;
    DateEnded: Date | null;
    DateModified: Date;
    ProjectedStartDate: Date | null;
    DateStarted: Date | null;
    Duration: number | null;
    DurationDays: number | null;
    ExternalIdentifier: string;
    ExternalJobID: string;
    Goal: string;
    Imaging: boolean;
    ImplantedDate: Date | null;
    Institution: Institution;
    InstitutionNamesSortable: string;
    IsHighThroughput: boolean;
    IsLocked: boolean;
    JobCharacteristicInstance: JobCharacteristicInstance[];
    JobCode: string;
    JobCohort: JobCohort[];
    JobGroup: JobGroup[];
    JobGroupOverage: number | null;
    JobID: string;
    JobIDSortable: string;
    JobInstitution: JobInstitution[];
    JobLine: JobLine[];
    JobLocation: JobLocation[];
    JobMaterial: JobMaterial[];
    JobMaterialPool: JobMaterialPool[];
    JobOrder: JobOrder[];
    JobReportReference: JobReportReference[];
    JobStandardPhrase: JobStandardPhrase[];
    JobTestArticle: JobTestArticle[];
    JobVariablePhrase: JobVariablePhrase[],
    JobWorkflowTask: JobWorkflowTask[];
    LeadScientist: string;
    Line: Line;
    LinesSortable: string;
    MessageMap: MessageMap[];
    ModifiedBy: string;
    Note: Note[];
    Notes: string;
    Order: Order[];
    Permit: Permit;
    Placeholder: Placeholder[];
    ProjectImplantDate: Date | null;
    ResearchDirector: string;
    Site: Site;
    StoredFileMap: StoredFileMap[];
    Study: Study;
    StudyDirector: string;
    StudyMonitor: string;
    TaskJob: TaskJob[];
    Version: number;
    cv_IACUCProtocol: cv_IACUCProtocol;
    cv_JobReport: cv_JobReport;
    cv_JobStatus: cv_JobStatus;
    cv_JobSubtype: cv_JobSubtype;
    cv_JobType: cv_JobType;
}

/// Internal model for job
export interface ExtendedJob extends Omit<Job, 'DurationDays'> {
    DurationDays: number | string;
}
