<div class="modal-title">
    <span class="modal-title-label">Assign Severity Score</span>
</div>
<div class="modal-body">
    <loading-overlay *ngIf="loading"></loading-overlay>
    <form *ngIf="!loading && !isWarningVersion" [formGroup]="form">
        <div *ngIf="animals?.length" class="modal-field">
            <table [dataSource]="animalsDataSource" climbTable>
                <tr climbHeaderRow *climbHeaderRowDef="displayedColumns"></tr>
                <tr climbRow *climbRowDef="let row; columns: displayedColumns;"></tr>
                <ng-container climbColumnDef="select" fixed onlyIcon>
                    <th climbHeaderCell *climbHeaderCellDef>
                        <climb-checkbox>
                            <input type="checkbox" climbCheckbox (click)="togglePageSelected()" [checked]="isPageAllSelected">
                        </climb-checkbox>
                    </th>
                    <td climbCell *climbCellDef="let item">
                        <climb-checkbox>
                            <input type="checkbox" climbCheckbox (click)="toggleRowSelect(item)"
                                [checked]="item.isSelected">
                        </climb-checkbox>
                    </td>
                </ng-container>
                <ng-container climbColumnDef="name">
                    <th climbHeaderCell *climbHeaderCellDef>Name</th>
                    <td climbCell *climbCellDef="let item">{{ item.AnimalName }}</td>
                </ng-container>
            </table>
            <climb-paginator [itemsCount]="totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [pageSizeOptions]="pageSizeOptions" (pageSizeChanged)="pageSizeChanged($event)"
                (pageChanged)="changeAnimalsTablePage($event.pageIndex)" class="line-top"
                aria-label="Select page of animals items">
            </climb-paginator>
        </div>
        <div class="modal-field modal-field-checkbox">
            <label class="col-form-label py-0">Prepared</label>
            <input type="checkbox" formControlName="IsPrepared">
        </div>
        <div class="modal-field">
            <label class="field-label">Severity Score</label>
            <active-vocab-select formControlName="SeverityScoreKey"
                [vocabChoices]="severities"
                [keyFormatter]="severityKeyFormatter"
                [optionFormatter]="severityFormatter"
                [defaultValue]="defaultSeverityKey"
                [loadDefaultValue]="true"
                [required]="true">
            </active-vocab-select>
        </div>
        <div class="modal-field">
            <label class="field-label">Severity External Comment</label>
            <active-vocab-select formControlName="SeverityExternalCommentKey"
                [vocabChoices]="severityExternalComments"
                [keyFormatter]="severityExternalCommentKeyFormatter"
                [optionFormatter]="severityExternalCommentFormatter"
                [defaultValue]="defaultSeverityExternalCommentKey"
                [loadDefaultValue]="true"
                [required]="true">
            </active-vocab-select>
        </div>
        <div class="modal-field modal-field-textarea">
            <label class="field-label">Severity Internal Comment</label>
            <climb-input #container>
                <textarea type="text" climbInput class="modal-textarea" placeholder="Enter Severity Internal Comment"
                    climbCharacterCounter [maxCharacterCount]="MAX_SYMBOLS" [container]="container"
                    formControlName="InternalComment"></textarea>
            </climb-input>
        </div>
    </form>
    <severity-score-warning-modal *ngIf="isWarningVersion" [newSeverityScore]="newSeverityScore"
        [form]="form" [animals]="animals"></severity-score-warning-modal>
</div>
<div *ngIf="!loading" class="modal-actions">
    <button *ngIf="!isWarningVersion" climbButton size="md" (click)="cancelClicked()">
        Cancel
    </button>
    <button *ngIf="isWarningVersion" climbButton size="md" (click)="backClicked()">
        Back
    </button>
    <button *ngIf="isShowNext" climbButton size="md" variant="primary" [disabled]="isPrimaryActionDisabled" (click)="nextClicked()">
        Next
    </button>
    <button *ngIf="isShowConfirm" climbButton size="md" variant="primary" [disabled]="isPrimaryActionDisabled" (click)="confirmClicked()">
        Confirm
    </button>
</div>
