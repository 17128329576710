import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Animal, cv_Severity, cv_SeverityExternalComment, Entity } from '@common/types';
import { SeverityScore } from '@common/types/models/severity-score.interface';
import { DataManagerService } from '@services/data-manager.service';
import { AnimalService } from '../services/animal.service';
import { AnimalSaveService } from '../services/animal-save.service';
import { SaveChangesService } from '@services/save-changes.service';
import { merge, Subscription } from 'rxjs';
import { SortDirection, SortState } from '@common/components/table/sort/sort.interface';
import { AnimalVocabService } from '../services/animal-vocab.service';
import { TableComponent } from '@common/components/table/table.component';
import { sortObjectArrayByAccessor, sortObjectArrayByProperty } from '@common/util';
import { DataContextService } from '@services/data-context.service';

interface ExtendedSeverityScore extends SeverityScore {
    isExpanded?: boolean;
}

@Component({
    selector: 'animal-severity-scores-table',
    templateUrl: './animal-severity-scores-table.component.html',
    styleUrls: ['./animal-severity-scores-table.component.scss'],
})
export class AnimalSeverityScoresTableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() animal: Animal;
    @Input() severityScores: SeverityScore[] = [];

    public severities: cv_Severity[] = [];
    public severityExternalComments: cv_SeverityExternalComment[] = [];
    public severityScoreHistoryExpanded = true;
    public loading = true;
    public saveSuccessfullySub: Subscription;
    public sortState: SortState;
    public dataSource: SeverityScore[] = [];

    public readonly MAX_INTERNAL_COMMENT_SYMBOLS = 1000;
    public readonly displayedColumns = [
        'delete',
        'jobName',
        'jobStatus',
        'prepared',
        'severityScore',
        'severityExternalComment',
        'severityInternalComment',
        'jobPermitNumber',
        'jobPermitOwner',
        'dateRecorded',
    ];

    @ViewChild(TableComponent) table: TableComponent<SeverityScore[]>;

    constructor (private dataManager: DataManagerService,
        private dataContext: DataContextService,
        private animalService: AnimalService,
        private animalSaveService: AnimalSaveService,
        private animalVocabService: AnimalVocabService,
        private saveChangesService: SaveChangesService,
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.initialize();

        this.saveSuccessfullySub = merge(
            this.saveChangesService.saveSuccessful$,
            this.animalSaveService.saveSuccessful$,
        )
        .subscribe(() => this.mapAnimalSeverityScoreHistory(this.animal));

        this.dataContext.onRejectEntityChange$.subscribe((entity: Entity<unknown>) => {
            if (entity.entityType.shortName !== 'SeverityScore') {
                return;
            }
            this.initSortOrder();
            this.sortChanged(this.sortState);
        });
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.animal) {
            await this.initialize();
        }
    }

    public async initialize(): Promise<void> {
        this.getCVs();
        await this.mapAnimalSeverityScoreHistory(this.animal);

        this.initSortOrder();
        this.loading = false;
    }

    public ngOnDestroy(): void {
        this.saveSuccessfullySub.unsubscribe();
    }

    private getCVs(): void {
        this.animalVocabService.severityScores$.subscribe((severityScores: cv_Severity[]) => {
            this.severities = severityScores;
        });

        this.animalVocabService.severityExternalComment$.subscribe((severityExternalComments: cv_SeverityExternalComment[]) => {
            this.severityExternalComments = severityExternalComments;
        });
    }

    public async mapAnimalSeverityScoreHistory(animal: Animal): Promise<void> {
        const severityScoreHistory: SeverityScore[] = (await this.animalService.getAnimalSeverityScoreHistory(animal.C_Material_key))?.data;

        const severityScoreMap: Map<number, SeverityScore[]> = new Map(severityScoreHistory.map(score => [score.C_SeverityScore_key, []]));

        for (const score of severityScoreHistory) {
            severityScoreMap.get(score.C_SeverityScore_key)?.push(score);
        }
        for (const animalSeverityScore of animal.SeverityScore) {
            animalSeverityScore.SeverityScoreHistory = severityScoreMap.get(animalSeverityScore.C_SeverityScore_key) || [];
        }
    }

    public deleteSeverityScore(item: SeverityScore): void {
        this.dataManager.deleteEntity(item);
        this.dataSource = this.dataSource.filter(severityScore => severityScore.C_SeverityScore_key !== item.C_SeverityScore_key);
    }

    public onExpandChange(item: ExtendedSeverityScore): void {
        item.isExpanded = !item.isExpanded;
    }

    public isSeverityHistoryExpanded(severityScore: ExtendedSeverityScore): boolean {
        return severityScore.SeverityScoreHistory?.length && severityScore.isExpanded;
    }

    public sortChanged(sortState: SortState): void {
        this.sortState = sortState;
        if (!this.sortState) {
            return;
        }
        if (!Object.keys(this.sortState?.state)?.length) {
            this.dataSource.sort((a, b) => a.SortOrder - b.SortOrder);
            this.reloadTable();
            return;
        }
        const { state } = sortState;
        if (state.prepared) {
            const direction = state.prepared.direction;
            this.sortSeverityScoreByProperty('IsPrepared', direction);
        }
        if (state.dateRecorded) {
            const direction = state.dateRecorded.direction;
            this.sortSeverityScoreByProperty('DateModified', direction);
        }
        if (state.severityScore) {
            const direction = state.severityScore.direction;
            sortObjectArrayByAccessor(this.dataSource,
                (severityScore: SeverityScore) => severityScore.cv_Severity.Severity,
                direction === 'desc'
            );
        }
        this.reloadTable();
    }

    private sortSeverityScoreByProperty(property: string, direction: SortDirection): void {
        if (direction) {
            sortObjectArrayByProperty(this.dataSource, property, direction === 'desc');
        } else {
            this.dataSource.sort((a, b) => a.SortOrder - b.SortOrder);
        }
    }

    public initSortOrder(): void {
        this.dataSource = [...this.severityScores];
        this.dataSource.sort((a, b) => b.DateModified.getTime() - a.DateModified.getTime());
        this.dataSource.forEach(severityScore => severityScore.SortOrder = this.severityScores.indexOf(severityScore) + 1);
        if (this.sortState) {
            this.sortChanged(this.sortState);
        } else {
            this.reloadTable();
        }
    }

    public reloadTable(): void {
        this.table.renderRows();
    }

    mapSeverity = (historySeverityKey: number): string | undefined => {
        return this.severities.find(item => item.C_Severity_key === historySeverityKey)?.Severity;
    }

    mapSeverityExternalComment = (historySeverityExternalCommentKey: number): string => {
        return this.severityExternalComments.find(item => item.C_SeverityExternalComment_key === historySeverityExternalCommentKey)?.SeverityExternalComment;
    }

    severityKeyFormatter = (value: cv_Severity): number => {
        return value.C_Severity_key;
    }

    severityFormatter = (value: cv_Severity): string => {
        return value.Severity;
    }

    severityExternalCommentKeyFormatter = (severityExternalComment: cv_SeverityExternalComment): number => {
        return severityExternalComment.C_SeverityExternalComment_key;
    }

    severityExternalCommentFormatter = (severityExternalComment: cv_SeverityExternalComment): string => {
        return severityExternalComment.SeverityExternalComment;
    }
}
