<climb-alert-inline *ngIf="message"
    [message]="message"
    status="warning"
    class="severity-score-warning-alert">
</climb-alert-inline>
<table [dataSource]="animalsDataSource" climbTable>
    <tr climbHeaderRow *climbHeaderRowDef="displayedColumns"></tr>
    <tr climbRow *climbRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container climbColumnDef="select" fixed onlyIcon>
        <th climbHeaderCell *climbHeaderCellDef>
        <climb-checkbox>
            <input type="checkbox" climbCheckbox (click)="togglePageSelected()" [checked]="isPageAllSelected">
        </climb-checkbox>
        </th>
        <td climbCell *climbCellDef="let item">
        <climb-checkbox>
            <input type="checkbox" climbCheckbox (click)="toggleRowSelect(item)" [checked]="item.isSelected">
        </climb-checkbox>
        </td>
    </ng-container>
    <ng-container climbColumnDef="name">
        <th climbHeaderCell *climbHeaderCellDef>Name</th>
        <td climbCell *climbCellDef="let item">{{ item.AnimalName }}</td>
    </ng-container>
    <ng-container climbColumnDef="recordedSeverityScores">
        <th climbHeaderCell *climbHeaderCellDef>Recorded Severity Score(s)</th>
        <td climbCell *climbCellDef="let item">
            {{ getRecordedSeverityScores(item) }}
        </td>
    </ng-container>
    <ng-container climbColumnDef="newSeverityScore">
        <th climbHeaderCell *climbHeaderCellDef>New Severity Score</th>
        <td climbCell *climbCellDef="let item">
            <span class="new-severity-score">
                <svg [climbIcon]="icons.arrowRight" size="sm"></svg> {{ newSeverityScore }}
            </span>
        </td>
    </ng-container>
</table>
<climb-paginator [itemsCount]="totalCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (pageSizeChanged)="pageSizeChanged($event)"
    (pageChanged)="changeAnimalsTablePage($event.pageIndex)"
    class="line-top"
    aria-label="Select page of animals items">
</climb-paginator>
