<div class="page-actions">
    <button climbButton
            variant="ghost"
            size="sm"
            onlyIcon
            aria-label="First page"
            [disabled]="previousButtonsDisabled()"
            (click)="firstPage()">
        <svg [climbIcon]="icons.firstPage"></svg>
    </button>

    <button climbButton
            variant="ghost"
            size="sm"
            onlyIcon
            aria-label="Previous page"
            [disabled]="previousButtonsDisabled()"
            (click)="previousPage()">
        <svg [climbIcon]="icons.chevronLeft"></svg>
    </button>

    <span class="range-label">
        <ng-container [ngSwitch]="itemsCount">
            <ng-container *ngSwitchCase="null">
                Page {{ pageIndex + 1 }} of more
            </ng-container>
            <ng-container *ngSwitchCase="0">
                Page 0 of 0
            </ng-container>
            <ng-container *ngSwitchDefault>
                Page {{ pageIndex + 1 }} of {{ [itemsCount, pageSize] | mapFn : pageTotal }}
            </ng-container>
        </ng-container>
    </span>

    <button climbButton
            variant="ghost"
            size="sm"
            onlyIcon
            aria-label="Next page"
            [disabled]="nextButtonsDisabled()"
            (click)="nextPage()">
        <svg [climbIcon]="icons.chevronRight"></svg>
    </button>

    <button climbButton
            variant="ghost"
            size="sm"
            onlyIcon
            aria-label="Last page"
            [disabled]="nextButtonsDisabled()"
            (click)="lastPage()">
        <svg [climbIcon]="icons.lastPage"></svg>
    </button>
</div>

<div class="data-source-info">
    <div *ngIf="pageSizeOptions">
        <label for="pageSize">Items per page</label>
        <climb-select size="md" [(ngModel)]="pageSize" (ngModelChange)="onPageSizeChange($event)">
            <climb-select-option
                *ngFor="let size of pageSizeOptions"
                style="min-width: 60px;"
                [value]="size">{{ size }}</climb-select-option>
        </climb-select>
    </div>
    <ng-container [ngSwitch]="itemsCount">
        <ng-container *ngSwitchCase="null">
            {{ [pageIndex, pageSize, itemsCount] | mapFn : pageItemRange }} of more
        </ng-container>
        <ng-container *ngSwitchCase="0">
            0 – 0 of 0
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{ [pageIndex, pageSize, itemsCount] | mapFn : pageItemRange }} of {{ itemsCount | number }}
        </ng-container>
    </ng-container>
</div>
