import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { BasePipesModule } from '@common/pipes/base-pipes.module';
import { BaseDirectivesModule } from '@common/directives/base-directives.module';
import { IconModule } from '@icons';
import { TableModule } from './table/table.module';
import { DropdownItemDirective } from '@common/components/dropdown/dropdown-item.directive';
import { PopoverOpenWithKeyArrowsDirective, PopoverTriggerForDirective } from '@common/components/popover/popover.directive';
import { AlertInlineComponent } from '@common/components/alert-inline/alert-inline.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { CheckboxComponent } from '@common/components/checkbox/checkbox.component';
import { DropdownComponent } from '@common/components/dropdown/dropdown.component';
import { InputComponent } from '@common/components/input/input.component';
import { InputMessagesAnimationComponent } from '@common/components/input/input-messages-animation.component';
import { LoadingOverlayComponent } from '@common/components/loading-overlay/loading-overlay.component';
import { LogoComponent } from '@common/components/logo/logo.component';
import { MenuItemComponent } from '@common/components/menu-item/menu-item.component';
import { PaginatorComponent } from '@common/components/paginator/paginator.component';
import { PopoverComponent } from '@common/components/popover/popover.component';
import { SelectComponent } from '@common/components/select/select.component';
import { SelectOptionComponent } from '@common/components/select/select-option/select-option.component';
import { SpinnerComponent } from '@common/components/spinner/spinner.component';
import { SwitchComponent } from '@common/components/switch/switch.component';
import { SwitchDirective } from '@common/components/switch/switch.directive';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        PortalModule,
        BasePipesModule,
        BaseDirectivesModule,
        IconModule,
        TableModule,
        FormsModule,
    ],
    declarations: [
        DropdownItemDirective,
        PopoverTriggerForDirective,
        PopoverOpenWithKeyArrowsDirective,
        AlertInlineComponent,
        ButtonComponent,
        CheckboxComponent,
        DropdownComponent,
        InputComponent,
        InputMessagesAnimationComponent,
        LoadingBarComponent,
        LoadingOverlayComponent,
        LogoComponent,
        MenuItemComponent,
        PaginatorComponent,
        PopoverComponent,
        SelectComponent,
        SelectOptionComponent,
        SpinnerComponent,
        SwitchComponent,
        SwitchDirective,
    ],
    exports: [
        TableModule,
        DropdownItemDirective,
        PopoverTriggerForDirective,
        PopoverOpenWithKeyArrowsDirective,
        AlertInlineComponent,
        ButtonComponent,
        CheckboxComponent,
        DropdownComponent,
        InputComponent,
        InputMessagesAnimationComponent,
        LoadingBarComponent,
        LoadingOverlayComponent,
        LogoComponent,
        MenuItemComponent,
        PaginatorComponent,
        PopoverComponent,
        SelectComponent,
        SelectOptionComponent,
        SpinnerComponent,
        SwitchComponent,
        SwitchDirective,
    ],
})
export class BaseComponentsModule { }
