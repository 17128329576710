import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@common/dialog/dialog-ref';
import { Animal, cv_Severity, cv_SeverityExternalComment, Entity } from '@common/types';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { ISeverityScoreModalForm } from '../models/severity-form-interfaces';
import { CLIMB_DIALOG_DATA } from '@common/dialog/dialog-data.token';
import { SaveChangesService } from '@services/save-changes.service';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { JobService } from 'src/app/jobs/job.service';
import { SeverityScore } from '@common/types/models/severity-score.interface';
import { AnimalService } from 'src/app/animals/services/animal.service';

export interface ExtendedAnimal extends Animal {
    isSelected?: boolean;
}

@Component({
    selector: 'severity-score-modal',
    templateUrl: './severity-score-modal.component.html',
    styleUrls: ['./severity-score-modal.component.scss'],
})
export class SeverityScoreModalComponent implements OnInit {
    public readonly MAX_SYMBOLS = 1000;
    public form: FormGroup;
    public animal: ExtendedAnimal;
    public animals: ExtendedAnimal[] = [];
    public animalsDataSource: ExtendedAnimal[] = [];
    public severities: cv_Severity[];
    public severityExternalComments: cv_SeverityExternalComment[];
    public loading = true;
    public componentName = 'severity-score-modal';
    public displayedColumns = ['select', 'name'];
    public isPageAllSelected = true;
    public pageIndex = 0;
    public pageSizeOptions = [5, 10, 15];
    public pageSize = this.pageSizeOptions[0];
    public totalCount: number;
    public animalsDataSourceOverrides: ExtendedAnimal[] = [];
    public isWarningVersion = false;
    public newSeverityScore: string;
    public defaultSeverityKey: number;
    public defaultSeverityExternalCommentKey: number;

    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    public get isPrimaryActionDisabled(): boolean {
        return this.form?.invalid || (this.animals && this.animals.every(animal => !animal.isSelected));
    }

    public get hasSelectedOverridingAnimals(): boolean {
        return this.form.get('Animals').value?.some((animal: ExtendedAnimal) => animal.SeverityScore.some(severity => this.hasAssignedSeverityScoresInActiveJobs(severity)))
            || this.animal?.SeverityScore.some(severity => this.hasAssignedSeverityScoresInActiveJobs(severity));
    }

    public get isShowNext(): boolean {
        return this.hasSelectedOverridingAnimals && !this.isWarningVersion;
    }

    public get isShowConfirm(): boolean {
        return (!this.isWarningVersion && !this.hasSelectedOverridingAnimals) || this.isWarningVersion;
    }

    constructor(private dialogRef: DialogRef,
        private fb: FormBuilder,
        private vocabularyService: VocabularyService,
        private saveChangesService: SaveChangesService,
        private toastrService: ToastrService,
        private jobService: JobService,
        private animalService: AnimalService,
        @Optional() @Inject(CLIMB_DIALOG_DATA) data: { animal: Entity<Animal>, animals: Entity<Animal>[] }
    ) {
        this.animal = data?.animal;
        this.animals = data?.animals;
        if (this.animals?.length) {
            for (const animal of this.animals) {
                animal.isSelected = true;
            }
            this.totalCount = this.animals.length;
            this.animalsDataSource = this.animals.slice(0, this.pageSize);
        }
    }

    public async ngOnInit(): Promise<void> {
        await this.getCVs();

        this.form = this.fb.group({
            Animals: [{ value: this.animals, disabled: !this.animals }, [Validators.required]],
            IsPrepared: [false],
            SeverityScoreKey: [this.defaultSeverityKey || null, [Validators.required, Validators.min(1)]],
            SeverityExternalCommentKey: [this.defaultSeverityExternalCommentKey || null, [Validators.required, Validators.min(1)]],
            InternalComment: ['', [Validators.maxLength(this.MAX_SYMBOLS)]],
        });
        this.loading = false;
    }

    private async getCVs(): Promise<void> {
        [this.severities, this.severityExternalComments] = await Promise.all([
            this.vocabularyService.getCV('cv_Severities'),
            this.vocabularyService.getCV('cv_SeverityExternalComments')
        ]);
        this.defaultSeverityKey = this.severities?.find(item => item.IsDefault)?.C_Severity_key;
        this.defaultSeverityExternalCommentKey = this.severityExternalComments?.find(item => item.IsDefault)?.C_SeverityExternalComment_key;
    }

    public async confirmClicked(): Promise<void> {
        const { IsPrepared, SeverityScoreKey, SeverityExternalCommentKey, InternalComment }: ISeverityScoreModalForm = this.form.value;
        const isBulk = Array.isArray(this.animals);
        const animals: Animal[] = isBulk ? this.form.get('Animals').value : [this.animal];

        for (const animal of animals) {
            const severityScoreMap = new Map(animal.SeverityScore
                .filter(score => this.hasAssignedSeverityScoresInActiveJobs(score))
                .map(score => [score.C_Job_key, score])
            );
            const jobMaterialsInNonEndStateJobs = animal.Material?.JobMaterial?.filter(item => !this.jobService.isJobInEndState(item.Job));

            if (!severityScoreMap.size && !jobMaterialsInNonEndStateJobs.length) {
                this.animalService.createSeverityScore({
                    C_Material_key: animal.C_Material_key,
                    C_Severity_key: SeverityScoreKey,
                    C_SeverityExternalComment_key: SeverityExternalCommentKey,
                    C_Job_key: null,
                    IsPrepared,
                    InternalComment,
                });
                continue;
            }
            for (const [materialKey, severityScore] of severityScoreMap.entries()) {
                severityScore.IsPrepared = IsPrepared;
                severityScore.C_Severity_key = SeverityScoreKey;
                severityScore.C_SeverityExternalComment_key = SeverityExternalCommentKey;
                severityScore.InternalComment = InternalComment;
            }
        }

        if (isBulk || this.hasSelectedOverridingAnimals) {
            const hasOneAnimal = animals.length === 1;
            const animalLabel = hasOneAnimal ? 'animal' : 'animals';
            const scoreLabel = hasOneAnimal ? 'score' : 'scores';
            try {
                await this.saveChangesService.saveChanges(this.componentName);
                if (animals.length) {
                    this.toastrService.showToast(`Assigned new severity ${scoreLabel} to ${animals.length} ${animalLabel}`, LogLevel.Success);
                }
            } catch (error) {
                if (animals.length) {
                    this.toastrService.showToast(`Failed to assign new severity ${scoreLabel} to ${animals.length} ${animalLabel}`, LogLevel.Error);
                } else {
                    this.toastrService.showToast(`Failed to assign new severity ${scoreLabel}`, LogLevel.Error);
                }
                return;
            }
        }

        this.dialogRef.close(animals);
    }

    private hasAssignedSeverityScoresInActiveJobs(severityScore: SeverityScore): boolean {
        return severityScore.C_Job_key && !this.jobService.isJobInEndState(severityScore.Job);
    }

    public cancelClicked(): void {
        this.dialogRef.close();
    }

    public backClicked(): void {
        if (this.animal) {
            this.animal.isSelected = false;
            this.animals = null;
            this.form.get('Animals').setValue(this.animals);
        }
        this.isWarningVersion = false;
    }

    public nextClicked(): void {
        if (this.animal) {
            this.animal.isSelected = true;
            this.animals = [this.animal];
            this.form.get('Animals').setValue(this.animals);
        }
        this.isWarningVersion = true;
        const severityScoreKey = this.form.get('SeverityScoreKey').value;
        this.newSeverityScore = this.severities.find(severity => severity.C_Severity_key === severityScoreKey).Severity;
    }

    public toggleRowSelect(row: ExtendedAnimal): void {
        row.isSelected = !row.isSelected;
        const newValue = this.animals.filter(item => item.isSelected);
        this.isPageAllSelected = this.checkPageAllSelected(this.animalsDataSource);
        this.form.get('Animals').setValue(newValue);
    }

    public togglePageSelected(): void {
        for (const animal of this.animalsDataSource) {
            animal.isSelected = !this.isPageAllSelected;
        }
        this.isPageAllSelected = this.checkPageAllSelected(this.animalsDataSource);
        const newValue = this.animals.filter(item => item.isSelected);
        this.form.get('Animals').setValue(newValue);
    }

    private checkPageAllSelected(animals: ExtendedAnimal[]): boolean {
        return animals.every(animal => animal.isSelected);
    }

    public changeAnimalsTablePage(pageIndex: number): void {
        this.pageIndex = pageIndex;
        const skip = pageIndex * this.pageSize;
        this.animalsDataSource = this.animals.slice(skip, this.pageSize + skip);
        this.isPageAllSelected = this.checkPageAllSelected(this.animalsDataSource);
        this.pageChange.emit(pageIndex);
    }

    public pageSizeChanged(event: number): void {
        this.pageSize = event;
        this.animalsDataSource = this.animals.slice(0, this.pageSize);
    }

    severityKeyFormatter = (severity: cv_Severity): number => {
        return severity.C_Severity_key;
    }

    severityFormatter = (severity: cv_Severity): string => {
        return severity.Severity;
    }

    severityExternalCommentKeyFormatter = (severityExternalComment: cv_SeverityExternalComment): number => {
        return severityExternalComment.C_SeverityExternalComment_key;
    }

    severityExternalCommentFormatter = (severityExternalComment: cv_SeverityExternalComment): string => {
        return severityExternalComment.SeverityExternalComment;
    }
}
