import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';
import { TranslationService } from '../services/translation.service';

export class AuditSeverityScoresTableOptions {
    options: TableOptions;
    isGLP = false;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
    ) {        
        this.options = this.getDefaultOptions();        
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: 'severity-scores-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,            
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    sortField: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    visible: true,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('AnimalName', 'AnimalName'),
                    cellClass: AuditClassFactory.createCellClass('animalname'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    visible: true,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', this.translationService.translate('Job') + 'Name'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Prepared',
                    field: 'Prepared',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('Prepared', 'Prepared'),
                    cellClass: AuditClassFactory.createCellClass('prepared'),
                },
                {
                    displayName: 'Severity Score',
                    field: 'SeverityScore',
                    visible: true,
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SeverityScore', 'SeverityScore'),
                    cellClass: AuditClassFactory.createCellClass('severityscore'),                
                },
                {
                    displayName: 'External Comment',
                    field: 'ExternalComment',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ExternalComment', 'ExternalComment'),
                    cellClass: AuditClassFactory.createCellClass('externalcomment'),
                },
                {
                    displayName: 'Internal Comment',
                    field: 'InternalComment',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('InternalComment', 'InternalComment'),
                    cellClass: AuditClassFactory.createCellClass('internalcomment'),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Permit Number',
                    field: 'JobPermitNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobPermitNumber', this.translationService.translate('Job') + 'PermitNumber'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'PermitNumber').toLowerCase()),
                },
                {
                    displayName: this.translationService.translate('Job') + ' Permit Owner',
                    field: 'JobPermitOwner',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobPermitOwner', this.translationService.translate('Job') + 'PermitOwner'),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'PermitOwner').toLowerCase()),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}
