import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

// import { AnimalVocabService } from './services/animal-vocab.service';

import { BaseFilter } from '../../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
    Permit,
    Resource,
    TaxonCharacteristic,
    cv_AnimalClassification,
    cv_AnimalMatingStatus,
    cv_AnimalStatus,
    cv_AnimalUse,
    cv_BreedingStatus,
    cv_Diet, cv_ExitReason,
    cv_Generation,
    cv_IACUCProtocol,
    cv_LineType,
    cv_MaterialOrigin,
    cv_Severity,
    cv_SeverityExternalComment,
    cv_Sex,
    cv_Taxon
} from '@common/types';
import { SettingService } from '../../settings/setting.service';
import { focusElementByQuery } from '@common/util';
import { AnimalVocabService } from '../services/animal-vocab.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { LoggingService } from '@services/logging.service';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { PermitService } from '../../permits/services/permit.service';

@Component({
    selector: 'animal-filter',
    templateUrl: './animal-filter.component.html'
})
export class AnimalFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() filter: any = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    // CVs
    animalMatingStatuses: any[] = [];
    animalStatuses: any[] = [];
    animalUses: any[] = [];
    breedingStatuses: any[] = [];
    animalClassifications: any[] = [];
    diets: any[] = [];
    exitReasons: any[] = [];
    generations: any[] = [];
    materialOrigins: any[] = [];
    sexes: any[] = [];
    taxons: any[] = [];
    iacucProtocols: any[] = [];
    lineTypes: cv_LineType[];
    severityScores: cv_Severity[];
    severityExternalComments: cv_SeverityExternalComment[];
    booleanValues: { Value: string, Key: boolean }[] = [{ Value: 'No', Key: false }, { Value: 'Yes', Key: true }];

    taxonCharacteristics: TaxonCharacteristic[];
    permits: Permit[];
    resources: Resource[];

    idValid = true;
    isGLP = false;
    isSeverityScore = false;

    isLoadingCharacteristics: boolean;

    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    constructor(
        private animalVocabService: AnimalVocabService,
        private activeModal: NgbActiveModal,
        private settingService: SettingService,
        private loggingService: LoggingService,
        private featureFlag: FeatureFlagService,
        private permitService: PermitService,
    ) {
        super(activeModal);
    }

    ngOnInit() {
        this.initFeatureFlags();
        this.setListViewCharacteristics();
        this.cloneFilter();
        this.filter.taxonCharacteristics = this.filter.taxonCharacteristics ? this.filter.taxonCharacteristics : {};

        this.getCVs();
        this.setPermits();

        focusElementByQuery('[data-auto-focus="animalName"]');
    }

    private cloneFilter() {
        const dateBornStart = this.filter.dateBornStart;
        const dateBornEnd = this.filter.dateBornEnd;
        const dateExitStart = this.filter.dateExitStart;
        const dateExitEnd = this.filter.dateExitEnd;
        const dateWeanStart = this.filter.dateWeanStart;
        const dateWeanEnd = this.filter.dateWeanEnd;
        const dateOriginStart = this.filter.dateOriginStart;
        const dateOriginEnd = this.filter.dateOriginEnd;
        const dateCreatedStart = this.filter.dateCreatedStart;
        const dateCreatedEnd = this.filter.dateCreatedEnd;
        const taxonCharacteristics = this.filter.taxonCharacteristics;

        this.filter = this.copyFilter(this.filter);

        this.filter.dateBornStart = dateBornStart;
        this.filter.dateBornEnd = dateBornEnd;
        this.filter.dateExitStart = dateExitStart;
        this.filter.dateExitEnd = dateExitEnd;
        this.filter.dateWeanStart = dateWeanStart;
        this.filter.dateWeanEnd = dateWeanEnd;
        this.filter.dateOriginStart = dateOriginStart;
        this.filter.dateOriginEnd = dateOriginEnd;
        this.filter.dateCreatedStart = dateCreatedStart;
        this.filter.dateCreatedEnd = dateCreatedEnd;
        this.filter.taxonCharacteristics = taxonCharacteristics;
    }


    getCVs() {
        this.animalVocabService.animalMatingStatuses$
            .subscribe((animalMatingStatuses: cv_AnimalMatingStatus[]) => {
                this.animalMatingStatuses = animalMatingStatuses;
            });
        
        this.animalVocabService.animalStatuses$
            .subscribe((animalStatuses: cv_AnimalStatus[]) => {
                this.animalStatuses = animalStatuses;
            });

        this.animalVocabService.animalUses$
            .subscribe((animalUses: cv_AnimalUse[]) => {
                this.animalUses = animalUses;
            });

        this.animalVocabService.breedingStatuses$
            .subscribe((breedingStatuses: cv_BreedingStatus[]) => {
                this.breedingStatuses = breedingStatuses;
            });

        this.animalVocabService.diets$
            .subscribe((diets: cv_Diet[]) => {
                this.diets = diets;
            });

        this.animalVocabService.exitReasons$
            .subscribe((exitReasons: cv_ExitReason[]) => {
                this.exitReasons = exitReasons;
            });

        this.animalVocabService.generations$
            .subscribe((generations: cv_Generation[]) => {
                this.generations = generations;
            });

        this.animalVocabService.materialOrigins$
            .subscribe((materialOrigins: cv_MaterialOrigin[]) => {
                this.materialOrigins = materialOrigins;
            });

        this.animalVocabService.sexes$
            .subscribe((sexes: cv_Sex[]) => {
                this.sexes = sexes;
            });

        this.animalVocabService.taxons$
            .subscribe((taxons: cv_Taxon[]) => {
                this.taxons = taxons;
            });

        this.animalVocabService.iacucProtocols$
            .subscribe((iacucProtocols: cv_IACUCProtocol[]) => {
                this.iacucProtocols = iacucProtocols;
            });

        this.animalVocabService.animalClassifications$
            .subscribe((animalClassifications: cv_AnimalClassification[]) => {
                this.animalClassifications = animalClassifications;
            });

        this.animalVocabService.lineTypes$
            .subscribe((lineTypes: cv_LineType[]) => {
                this.lineTypes = lineTypes;
            })

        this.animalVocabService.severityScores$
            .subscribe((severityScores: cv_Severity[]) => {
                this.severityScores = severityScores;
            })

        this.animalVocabService.severityExternalComment$
            .subscribe((severityExternalComments: cv_SeverityExternalComment[]) => {
                this.severityExternalComments = severityExternalComments;
            })

        this.animalVocabService.resources$
            .subscribe((data) => {
                this.resources = data.filter((resource) => {
                    return !resource.IsGroup;
                });
    });
    }

    setListViewCharacteristics(): void {
        this.isLoadingCharacteristics = true;
        this.settingService.getTaxonCharacteristicsShownInListView().then((characteristics: TaxonCharacteristic[]) => {
            this.taxonCharacteristics = characteristics;
            this.isLoadingCharacteristics = false;
        });
    }

    public async setPermits(): Promise<void> {
        this.permits = await this.permitService.getAllPermits();
    }

    onCharacteristicValueChange(characteristicName: string, characteristicValue: string) {
        this.filter.taxonCharacteristics[characteristicName] = characteristicValue;
    }

    onValidInput($event: any) {
        this.idValid = $event;
    }

    filterClicked(event: Event) {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.closeClicked();
    }

    closeClicked() {
        this.close.emit();
        this.activeModal.dismiss();
    }

    clearClicked() {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.closeClicked();
    }

    initFeatureFlags(): void {
        this.isGLP = this.featureFlag.isFlagOn('IsGLP');
        this.isSeverityScore = this.featureFlag.isFlagOn('SeverityScore');
    }
}
