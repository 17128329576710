<table [dataSource]="dataSource" climbTable (sortChanged)="sortChanged($event)">
    <tr climbHeaderRow *climbHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr climbRow *climbRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container climbColumnDef="delete" fixed onlyIcon>
        <th climbHeaderCell *climbHeaderCellDef></th>
        <td climbCell *climbCellDef="let item">
            <button climbDeleteItemButton
                    [disabled]="item.Job"
                    (click)="deleteSeverityScore(item)"
                    title="Delete severity score">
            </button>
        </td>
    </ng-container>

    <ng-container climbColumnDef="jobName">
        <th climbHeaderCell *climbHeaderCellDef>{{'Job' | translate}} Name</th>
        <td class="job-name" climbCell *climbCellDef="let item">
            {{ item.Job?.JobID || '-' }}
            <div *ngIf="item.SeverityScoreHistory?.length">
                <climb-expand [(model)]="item.isExpanded" label="History"></climb-expand>
                History
            </div>
        </td>
    </ng-container>

    <ng-container climbColumnDef="jobStatus">
        <th climbHeaderCell *climbHeaderCellDef>{{'Job' | translate}} Status</th>
        <td climbCell *climbCellDef="let item">
            {{ item.Job?.cv_JobStatus.JobStatus || '-' }}
        </td>
    </ng-container>

    <ng-container climbColumnDef="prepared" sortable>
        <th climbHeaderCell *climbHeaderCellDef>Prepared</th>
        <td climbCell *climbCellDef="let item">
            <climb-checkbox>
                <input type="checkbox" climbCheckbox [(ngModel)]="item.IsPrepared" [checked]="item.IsPrepared">
            </climb-checkbox>
            <div *ngIf="isSeverityHistoryExpanded(item)" class="history">
                <climb-checkbox *ngFor="let history of item.SeverityScoreHistory">
                    <input type="checkbox" climbCheckbox [(ngModel)]="history.IsPrepared" [checked]="history.IsPrepared" disabled>
                </climb-checkbox>
            </div>
        </td>
    </ng-container>

    <ng-container climbColumnDef="severityScore" sortable>
        <th climbHeaderCell *climbHeaderCellDef>Severity Score</th>
        <td climbCell *climbCellDef="let item" style="position: relative">
            <active-vocab-select data-automation-id="animal-severity-score-dropdown"
                [(model)]="item.C_Severity_key"
                [vocabChoices]="severities"
                [keyFormatter]="severityKeyFormatter"
                [optionFormatter]="severityFormatter">
            </active-vocab-select>
            <div *ngIf="isSeverityHistoryExpanded(item)" class="history">
                <p *ngFor="let history of item.SeverityScoreHistory">{{ mapSeverity(history?.C_Severity_key) }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container climbColumnDef="severityExternalComment">
        <th climbHeaderCell *climbHeaderCellDef>Severity External Comment</th>
        <td climbCell *climbCellDef="let item">
            <active-vocab-select data-automation-id="animal-severity-external-comment-dropdown"
                [(model)]="item.C_SeverityExternalComment_key"
                [vocabChoices]="severityExternalComments"
                [keyFormatter]="severityExternalCommentKeyFormatter"
                [optionFormatter]="severityExternalCommentFormatter">
            </active-vocab-select>
            <div *ngIf="isSeverityHistoryExpanded(item)" class="history">
                <p *ngFor="let history of item.SeverityScoreHistory">{{ mapSeverityExternalComment(history?.C_SeverityExternalComment_key) }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container climbColumnDef="severityInternalComment">
        <th climbHeaderCell *climbHeaderCellDef>Severity Internal Comment</th>
        <td climbCell *climbCellDef="let item" class="internal-comment" style="white-space: nowrap">
            <climb-input #container size="md">
                <textarea climbInput
                    type="text"
                    class="internal-comment-textarea"
                    [container]="container"
                    climbCharacterCounter
                    [maxCharacterCount]="MAX_INTERNAL_COMMENT_SYMBOLS"
                    [(ngModel)]="item.InternalComment"
                    placeholder="Enter Severity Internal Comment">
                </textarea>
            </climb-input>
            <div *ngIf="isSeverityHistoryExpanded(item)" class="history">
                <p *ngFor="let history of item.SeverityScoreHistory">{{ history.InternalComment }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container climbColumnDef="jobPermitNumber">
        <th climbHeaderCell *climbHeaderCellDef>{{'Job' | translate}} Permit Number</th>
        <td climbCell *climbCellDef="let item">
            {{ item.Job?.Permit?.PermitNumber || '-' }}
        </td>
    </ng-container>

    <ng-container climbColumnDef="jobPermitOwner">
        <th climbHeaderCell *climbHeaderCellDef>{{'Job' | translate}} Permit Owner</th>
        <td climbCell *climbCellDef="let item">
            {{ item.Job?.Permit?.Resource?.ResourceName || '-' }}
        </td>
    </ng-container>

    <ng-container climbColumnDef="dateRecorded" sortable>
        <th climbHeaderCell *climbHeaderCellDef>Date Recorded</th>
        <td climbCell *climbCellDef="let item">
            {{ item.DateModified | formatShortDate }}
            <div *ngIf="isSeverityHistoryExpanded(item)" class="history">
                <p *ngFor="let history of item.SeverityScoreHistory">{{ history.DateModified | formatShortDate }}</p>
            </div>
        </td>
    </ng-container>
</table>
