import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { take } from 'rxjs/operators';
import { ResourceService } from 'src/app/resources';
import { Resource } from '@common/types';

@Injectable()
export class AnimalVocabService {

    // vocab Observables
    public get sexes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Sexes')).pipe(take(1));
    }
    public get animalMatingStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalMatingStatuses')).pipe(take(1));
    }
    public get animalStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalStatuses')).pipe(take(1));
    }
    public get animalUses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalUses')).pipe(take(1));
    }
    public get materialOrigins$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_MaterialOrigins')).pipe(take(1));
    }
    public get generations$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Generations')).pipe(take(1));
    }
    public get taxons$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Taxons')).pipe(take(1));
    }
    public get exitReasons$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_ExitReasons')).pipe(take(1));
    }
    public get diets$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Diets')).pipe(take(1));
    }
    public get breedingStatuses$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_BreedingStatuses')).pipe(take(1));
    }
    public get animalClassifications$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_AnimalClassifications')).pipe(take(1));
    }
    public get iacucProtocols$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_IACUCProtocols')).pipe(take(1));
    }
    public get physicalMarkerTypes$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_PhysicalMarkerTypes')).pipe(take(1));
    }
    public get genotypeAssays$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_GenotypeAssays', 'SortOrder')).pipe(take(1));
    }
    public get genotypeSymbols$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_GenotypeSymbols', 'SortOrder')).pipe(take(1));
    }
    public get animalCommentStatuses$(): Observable<any[]> {
        return defer(async () => {
            const cv = await this.vocabularyService.getCV('cv_AnimalCommentStatuses');

            // If no other options are available, use system-generated status
            if (cv.length === 0) {
                return Promise.resolve([await this.vocabularyService.getSystemGeneratedCV('cv_AnimalCommentStatuses')]);
            }

            return Promise.resolve(cv);
        }).pipe(take(1));
    }
    public get resources$(): Observable<Resource[]> {
        return defer(() => this.resourceService.getAllResources()).pipe(take(1));
    }
    
    public get lineTypes$(): Observable<any[]>{
        return defer(() => this.vocabularyService.getCV('cv_LineTypes')).pipe(take(1));
    }
    public get severityScores$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_Severities')).pipe(take(1));
    }
    public get severityExternalComment$(): Observable<any[]> {
        return defer(() => this.vocabularyService.getCV('cv_SeverityExternalComments')).pipe(take(1));
    }

    constructor(
        private vocabularyService: VocabularyService,
        private resourceService: ResourceService,
    ) {
        this._init();
    }

    _init() {
        // Material types need to be populated in the dataManager in order to create animal records
        // TODO: Create a method to prime the dataManager with vocabularies and take this out
        this.vocabularyService.ensureCVLoaded('cv_MaterialTypes');

        // These are required for the navigation properties to display genotypes
        this.vocabularyService.getCV('cv_GenotypeAssays');
        this.vocabularyService.getCV('cv_GenotypeSymbols');

        // These are required for conditional display of characteristics based on data type
        this.vocabularyService.ensureCVLoaded('cv_DataTypes');
    }
}
