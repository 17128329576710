import { NgModule } from '@angular/core';
import { SeverityScoreModalComponent } from './severity-score-modal.component';
import { BaseComponentsModule } from '@common/components/base-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseDirectivesModule } from '@common/directives/base-directives.module';
import { ClimbCommonModule } from '@common/climb-common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SeverityScoreWarningModalComponent } from './severity-score-warning-modal.component';


@NgModule({
    imports: [
        BaseComponentsModule,
        BaseDirectivesModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        ClimbCommonModule,
    ],
    declarations: [
        SeverityScoreModalComponent,
        SeverityScoreWarningModalComponent,
    ],
    exports: [
        SeverityScoreModalComponent,
        SeverityScoreWarningModalComponent,
    ]
})
export class SeverityScoreModalModule { }
