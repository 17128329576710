<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Animals Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <div class="col-md-2">
                <label class="col-form-label mb-0 pb-0" style="display: block;">Name</label>
                <div class="text-right">
                    <one-many-toggle #nameToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="nameToggle" [(singleModel)]="filter.animalName"
                                    [(manyModel)]="filter.animalNames">
                    <multi-control name="single">
                        <input type="text" name="animalName" [(ngModel)]="filter.animalName"
                               class="form-control input-medium" data-auto-focus="animalName" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.animalNames" [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3">
                        </multi-paste-input>
                        <small class="form-text text-muted">
                            Names separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
            <label class="col-md-2 col-form-label">ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.identifiers" [isNumeric]="true" [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   (validInput)="onValidInput($event)" [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Sex</label>
                    <div class="col-md-8">
                        <select name="sex" [(ngModel)]="filter.C_Sex_key" class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of sexes" [value]="item.C_Sex_key">
                                {{item.Sex}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Status</label>
                    <div class="col-md-8">
                        <climb-multi-select [(model)]="filter.C_AnimalStatus_keys" [values]="animalStatuses"
                                            [keyProperty]="'C_AnimalStatus_key'" [valueProperty]="'AnimalStatus'"
                                            [placeholder]="'Select statuses...'"></climb-multi-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label" *ngIf="isGLP">Held For</label>
                    <div class="col-md-8" *ngIf="isGLP">
                        <input type="text" name="heldFor" [(ngModel)]="filter.heldFor"
                               class="form-control input-medium" maxlength="250"/>
                    </div>
                </div>
            </div>
            <label class="col-md-2 col-form-label">Microchip ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.microchipIdentifiers" [isNumeric]="false"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Species</label>
            <div class="col-md-4">
                <select name="taxon" [(ngModel)]="filter.C_Taxon_key" class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of taxons" [value]="item.C_Taxon_key">
                        {{item.Taxon}}
                    </option>
                </select>
            </div>
            <label class="col-md-2 col-form-label" *ngIf="isGLP">Classification</label>
            <div class="col-md-4" *ngIf="isGLP">
                <climb-multi-select [(model)]="filter.C_AnimalClassification_keys" [values]="animalClassifications"
                                    [keyProperty]="'C_AnimalClassification_key'" [valueProperty]="'AnimalClassification'"
                                    [placeholder]="'Select classifications...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Use</label>
            <div class="col-md-4">
                <select name="animalUse" [(ngModel)]="filter.C_AnimalUse_key" class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of animalUses" [value]="item.C_AnimalUse_key">
                        {{item.AnimalUse}}
                    </option>
                </select>
            </div>
            <label class="col-md-2 col-form-label">Origin</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_MaterialOrigin_keys" [values]="materialOrigins"
                                    [keyProperty]="'C_MaterialOrigin_key'" [valueProperty]="'MaterialOrigin'"
                                    [placeholder]="'Select origins...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">External ID</label>
            <div class="col-md-4">
                <input type="text" name="externalIdentifier" [(ngModel)]="filter.externalIdentifier"
                       class="form-control input-medium" />
            </div>
            <label class="col-md-2 col-form-label" *ngIf="isGLP">Alternate Physical ID</label>
            <div class="col-md-4" *ngIf="isGLP">
                <input type="text" name="alternatePhysicalID" [(ngModel)]="filter.alternatePhysicalID"
                       class="form-control input-medium" />
            </div>
            <label class="col-md-2 col-form-label" *ngIf="!isGLP">Owner</label>
            <div class="col-md-4" *ngIf="!isGLP">
                <user-select [(model)]="filter.owner" [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row" *ngIf="isGLP">
            <label class="col-md-2 col-form-label offset-md-6">Owner</label>
            <div class="col-md-4">
                <user-select [(model)]="filter.owner" [forceValidSelection]="false"></user-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">{{'Job' | translate}}</label>
            <div class="col-md-4">
                <input type="text" name="jobId" [(ngModel)]="filter.jobID" class="form-control input-medium" />
            </div>
            <label class="col-md-2 col-form-label">Location</label>
            <div class="col-md-4">
                <input type="text" name="location" [(ngModel)]="filter.location" class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Sire/Dam</label>
            <div class="col-md-4">
                <input type="text" name="sireDam" [(ngModel)]="filter.materialPoolMaterial"
                       class="form-control input-medium">
            </div>
            <label class="col-md-2 col-form-label">Plate ID</label>
            <div class="col-md-4">
                <input type="text" name="plateId" [(ngModel)]="filter.plateID" class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Cohort</label>
            <div class="col-md-4">
                <cohort-multiselect [(model)]="filter.cohorts"></cohort-multiselect>
            </div>
            <label class="col-md-2 col-form-label">Housing ID</label>
            <div class="col-md-4">
                <housing-multiselect [(model)]="filter.housingUnits"></housing-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Diet</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_Diet_keys" [values]="diets" [keyProperty]="'C_Diet_key'"
                                    [valueProperty]="'Diet'" [placeholder]="'Select diets...'"></climb-multi-select>
            </div>
            <label class="col-md-2 col-form-label">Breeding Status</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_BreedingStatus_keys" [values]="breedingStatuses"
                                    [keyProperty]="'C_BreedingStatus_key'" [valueProperty]="'BreedingStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Birth ID</label>
            <div class="col-md-4">
                <birth-multiselect [(model)]="filter.birthIds"></birth-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Generation</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_Generation_keys" [values]="generations"
                                    [keyProperty]="'C_Generation_key'" [valueProperty]="'Generation'"
                                    [placeholder]="'Select generations...'"></climb-multi-select>
            </div>
            <label class="col-md-2 col-form-label">Mating Status</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_AnimalMatingStatus_keys" [values]="animalMatingStatuses"
                                    [keyProperty]="'C_AnimalMatingStatus_key'" [valueProperty]="'AnimalMatingStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Construct</label>
            <div class="col-md-4">
                <construct-multiselect [(model)]="filter.constructs"></construct-multiselect>
            </div>
            <label class="col-md-2 col-form-label">Marker</label>
            <div class="col-md-4">
                <input type="text" name="physicalMarker" [(ngModel)]="filter.physicalMarker"
                       class="form-control input-medium" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">{{'Study' | translate}}</label>
            <div class="col-md-10">
                <study-select [(model)]="filter.C_Study_key" [excludeLocked]="false"></study-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">
                <span>Genotype</span>
                <span class="a_label_checkbox">
                    <br /><span class="subfilter-block">And</span>
                    <input type="checkbox" [(ngModel)]="filter.genotypeAnd" name="genotypeAnd">
                </span>
            </label>
            <div class="col-md-10">
                <genotype-combo-select [(model)]="filter.genotypeCombos"></genotype-combo-select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Protocol</label>
            <div class="col-md-10">
                <protocol-multiselect [(model)]="filter.protocols"></protocol-multiselect>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Name Range</label>
            <div class="col-md-5">
                <animal-name-range-select [(model)]="filter.animalNameFrom" [placeholder]="'From&hellip;'">
                </animal-name-range-select>
            </div>
            <div class="col-md-5">
                <animal-name-range-select [(model)]="filter.animalNameTo" [placeholder]="'To&hellip;'">
                </animal-name-range-select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-2">
                <label class="col-form-label mb-0 pb-0" style="display: block;">Shipment</label>
                <div class="text-right">
                    <one-many-toggle #shipmentToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="shipmentToggle" [(singleModel)]="filter.shipmentID"
                                    [(manyModel)]="filter.shipmentIDs">
                    <multi-control name="single">
                        <input type="text" name="shipmentID" [(ngModel)]="filter.shipmentID"
                               class="form-control input-medium" data-auto-focus="shipmentID" />
                        <small class="form-text text-muted">
                            Find shipment IDs <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.shipmentIDs" [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3">
                        </multi-paste-input>
                        <small class="form-text text-muted">
                            Shipment IDs separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
            <div class="col-md-2">
                <label class="col-form-label mb-0 pb-0" style="display: block;">Vendor ID</label>
                <div class="text-right">
                    <one-many-toggle #vendorToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="vendorToggle" [(singleModel)]="filter.vendorID"
                                    [(manyModel)]="filter.vendorIDs">
                    <multi-control name="single">
                        <input type="text" name="vendorID" [(ngModel)]="filter.vendorID"
                               class="form-control input-medium" data-auto-focus="vendorID" />
                        <small class="form-text text-muted">
                            Find vendor IDs <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.vendorIDs" [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3">
                        </multi-paste-input>
                        <small class="form-text text-muted">
                            Vendor IDs separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Order ID</label>
            <div class="col-md-4">
                <order-multiselect [(model)]="filter.orders"
                                   [animalOrdersOnly]="true"></order-multiselect>
            </div>
            <label class="col-md-2 col-form-label" *ngIf="isGLP">CITES Number</label>
            <div class="col-md-4" *ngIf="isGLP">
                <input type="text" name="cITESNumber" [(ngModel)]="filter.CITESNumber"
                        class="form-control input-medium" maxlength="250"/>
            </div>        
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Arrival Date Range</label>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Arrival Date Start" [(ngModel)]="filter.dateOriginStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Arrival Date End" [(ngModel)]="filter.dateOriginEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Birth Date Range</label>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Birth Date Start" [(ngModel)]="filter.dateBornStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Birth Date End" [(ngModel)]="filter.dateBornEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Death/&#8203;Exit Date Range</label>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Death/Exit Date Start" [(ngModel)]="filter.dateExitStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Death/Exit Date Start" [(ngModel)]="filter.dateExitEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Death/&#8203;Exit Reason</label>
            <div class="col-md-10">
                <select name="exitReason" [(ngModel)]="filter.C_ExitReason_key" class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of exitReasons" [value]="item.C_ExitReason_key">
                        {{item.ExitReason}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Wean Date Range</label>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Wean Date Start" [(ngModel)]="filter.dateWeanStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
            <div class="col-md-5">
                <climb-ngb-date #dateControl="ngModel" name="Wean Date End" [(ngModel)]="filter.dateWeanEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-4">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>

            <label class="col-md-2 col-form-label">{{'Line' | translate}} (Stock)</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.stockIDs" [isNumeric]="false" [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <ng-container *ngIf="isSeverityScore">
                <label class="col-md-2 col-form-label">{{'Line' | translate}} Types</label>
                <div class="col-md-4">
                    <climb-multi-select
                        [(model)]="filter.C_LineType_keys"
                        [values]="lineTypes"
                        [keyProperty]="'C_LineType_key'"
                        [valueProperty]="'LineType'"
                        [placeholder]="'Select types...'">
                    </climb-multi-select>
                </div>
            </ng-container>
            <div class="col-md-2">
                <label class="col-form-label mb-0 pb-0" style="display: block;">Parent Mating ID</label>
                <div class="text-right">
                    <one-many-toggle #matingIDToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="matingIDToggle" [(singleModel)]="filter.matingID"
                                    [(manyModel)]="filter.matingIDs">
                    <multi-control name="single">
                        <input type="text" name="matingID" [(ngModel)]="filter.matingID"
                               class="form-control input-medium" data-auto-focus="matingID" />
                        <small class="form-text text-muted">
                            Find parent mating IDs <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.matingIDs" [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3">
                        </multi-paste-input>
                        <small class="form-text text-muted">
                            Parent mating IDs separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">IACUC Protocol</label>
            <div class="col-md-10">
                <select name="iacucProtocol" [(ngModel)]="filter.C_IACUCProtocol_key" class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of iacucProtocols" [value]="item.C_IACUCProtocol_key">
                        {{item.IACUCProtocol}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 col-form-label">Permit</label>
            <div class="col-md-4">
                <climb-multi-select
                    [(model)]="filter.C_Permit_keys"
                    [values]="permits"
                    [keyProperty]="'C_Permit_key'"
                    [valueProperty]="'PermitNumber'"
                    [placeholder]="'Select permits...'">
                </climb-multi-select>
            </div>
            <label class="col-md-2 col-form-label">Permit Owner</label>
            <div class="col-md-4">
                <climb-multi-select
                    [(model)]="filter.permitOwnerKeys"
                    [values]="resources"
                    [keyProperty]="'C_Resource_key'"
                    [valueProperty]="'ResourceName'"
                    [placeholder]="'Select permit owners...'">
                </climb-multi-select>
            </div>
        </div>
        <ng-container *ngIf="isSeverityScore">
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Prepared</label>
                <div class="col-md-4">
                    <climb-multi-select [(model)]="filter.isPrepared"
                        [values]="booleanValues"
                        [keyProperty]="'Key'"
                        [valueProperty]="'Value'"
                        [placeholder]="'Select...'">
                    </climb-multi-select>
                </div>
                <label class="col-md-2 col-form-label">Severity Score</label>
                <div class="col-md-4">
                    <climb-multi-select
                        [(model)]="filter.C_Severity_keys"
                        [values]="severityScores"
                        [keyProperty]="'C_Severity_key'"
                        [valueProperty]="'Severity'"
                        [placeholder]="'Select severity...'">
                    </climb-multi-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Severity External Comment</label>
                <div class="col-md-4">
                    <climb-multi-select
                        [(model)]="filter.C_SeverityExternalComment_keys"
                        [values]="severityExternalComments"
                        [keyProperty]="'C_SeverityExternalComment_key'"
                        [valueProperty]="'SeverityExternalComment'"
                        [placeholder]="'Select external comment...'">
                    </climb-multi-select>
                </div>
                <div class="col-md-2">
                    <label class="col-form-label mb-0 pb-0" style="display: block;">Severity Internal <br/> Comment</label>
                    <div class="text-right">
                        <one-many-toggle #internalCommentToggle></one-many-toggle>
                    </div>
                </div>
                <div class="col-md-4">
                    <multi-control-wrap [toggleComponent]="internalCommentToggle" [(singleModel)]="filter.internalComment"
                                        [(manyModel)]="filter.internalComments">
                        <multi-control name="single">
                            <input type="text" name="internalComment" [(ngModel)]="filter.internalComment"
                                class="form-control input-medium" data-auto-focus="internalComment" />
                            <small class="form-text text-muted">
                                Find internal comments <em>containing</em> this value.
                            </small>
                        </multi-control>
                        <multi-control name="many">
                            <multi-paste-input [(model)]="filter.internalComments" [limit]="MULTI_PASTE_INPUT_LIMIT" [rows]="3">
                            </multi-paste-input>
                            <small class="form-text text-muted">
                                Internal Comments separated by
                                <code>,</code> or <code>Enter</code>.
                                Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                            </small>
                        </multi-control>
                    </multi-control-wrap>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Severity Date Range</label>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel" name="Severity Created Date Start" [(ngModel)]="filter.severityDateCreatedStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
                </div>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel" name="Severity Created Date End" [(ngModel)]="filter.severityDateCreatedEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Created By</label>
                <div class="col-md-10">
                    <user-select [(model)]="filter.createdBy" [forceValidSelection]="false"></user-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Created Date Range</label>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel" name="Created Date Start" [(ngModel)]="filter.dateCreatedStart" placeholder="From&hellip;" ngDefaultControl></climb-ngb-date>
                </div>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel" name="Created Date End" [(ngModel)]="filter.dateCreatedEnd" placeholder="To&hellip;" ngDefaultControl></climb-ngb-date>
                </div>
            </div>
        </ng-container>
        <br />
        <ng-container *ngIf="taxonCharacteristics && taxonCharacteristics.length > 0">
            <div class="form-group row">
                <span class="col-md-4"><b>Workgroup Characteristics</b></span>
            </div>
            <div class="dropdown-divider">
            </div>
            <ng-container *ngIf="isLoadingCharacteristics">
                <loading-overlay [message]="'Loading'"></loading-overlay>
            </ng-container>
            <ng-container *ngIf="!isLoadingCharacteristics">
                <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristics;let i = index">
                    <div class="form-group row" *ngIf="filter.taxonCharacteristics">
                        <label class="col-md-2 col-form-label">{{taxonCharacteristic.CharacteristicName}}</label>
                        <div class="col-md-4">
                            <input type="text" name="characteristic-input-{{i}}" [(ngModel)]="filter.taxonCharacteristics[taxonCharacteristic.C_TaxonCharacteristic_key]"
                                   class="form-control input-medium" />
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>


    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit" class="btn btn-fw-wide btn-lg btn-primary" (click)="filterClicked($event)"
                [disabled]="!idValid" [attr.title]="idValid ? '': 'Invalid characters in the ID field'">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button" 
                    class="btn btn-fw-wide btn-lg btn-secondary" 
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button" class="btn btn-lg btn-secondary" (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>
